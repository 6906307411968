@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --white: #FFF;
    --black: #000;
    --gray: #667085;
    --dark-gray: #344054;
    --light-gray: #f2f4f7;
    --blue: #0c83b3;
    --dark-blue: #005b7f;
    --light-blue: #33B6FF;
    --deep-blue: #123a72;
    --tab-blue: #d6e5ea;
    --lemon-green: #bbcd0e;
    --light-green: #ebf0b7;
    --orange: #F2B45A;
}

* {
    outline: none;
}

body {
    background-color: var(--white) !important;
}

.text-light-blue {
    color: var(--light-blue);
}

.fw-100 {
    font-weight: 100 !important;
}
.fw-200 {
    font-weight: 200 !important;
}
.fw-300 {
    font-weight: 300 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-800 {
    font-weight: 800 !important;
}
.fw-900 {
    font-weight: 900 !important;
}

#top-bar {
     height: 85px;
     border-bottom: 1px solid #eaecf0;
 }

#top-menu {
    height: 65px;
    border-bottom: 1px solid #eaecf0;
    background-color: #5f7c89;
}

#top-bar .dropdown>button {
    background-color: var(--white);
    border: 1px solid var(--light-blue);
    border-radius: 8px;
    width: 110px;
    height: 37px;
    color: var(--light-blue);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#top-menu #menu ul {
    list-style-type: none;
    padding: 0;
    display: inline-flex;
    margin-bottom: 0;
}

#top-menu #menu li {
    margin-right: 36px;
    padding: 8px 10px;
    border-radius: 6px;
}

#top-menu #menu li:hover {
    background-color: #f9fafb;
}

#top-menu #menu li:hover a {
    color: #005B7F;
}

#top-menu #menu li a {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
}

#faixa {
    background: #fff url(../images/linha.png) repeat center 0px;
    height: 15px;
}

form#search-box #search-box-options {
    background-color: var(--white);
    color: var(--gray);
    border-top: 1px solid #d0d5dd;
    border-left: 1px solid #d0d5dd;
    border-bottom: 1px solid #d0d5dd;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px !important;
    height: 40px;
    padding: 5px 15px;
}

form#search-box button[type=submit] {
    background-color: var(--white);
    color: var(--gray);
    border-top: 1px solid #d0d5dd;
    border-right: 1px solid #d0d5dd;
    border-bottom: 1px solid #d0d5dd;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px !important;
    height: 40px;
    padding: 5px 15px;
}

#top-bar .menu i.fas {
    color: #000;
}

#sidebar section {
    border: none !important;
}

#sidebar #menu-col .list-group-item {
    border: none !important;
    padding: 0.7rem !important;
}

#sidebar #menu-col .list-group-item:hover {
    color: var(--light-blue);
    background-color: var(--light-gray);
    transition: 0.3s;
}

#content {
    border: none !important;
}

#homepage p {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 24px;
    color: var(--dark-gray);
}

#home-acessos .coluna {
    margin-bottom: 33px;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    transition: .3s;
}

#home-acessos .coluna:hover {
    background-color: #ebeef1;
}

#home-acessos .text a,
#home-acessos .text a:hover {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: var(--dark-gray);
    text-decoration: none;
}

#como-pesquisar {
    background: #f9fafb;
    border-radius: 16px;
    margin-top: 20px;
}

#como-pesquisar a:not(.mail) {
    background-color: var(--light-blue);
    border-radius: 8px;
    color: var(--white);
    min-width: 122px;
    padding: 7px 19px;
    height: 39px;
    border: none;
}

#popular-this-week {
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #f2f7f8;
}

#popular-this-week ul {
    list-style-type: none;
    padding-left: 0;
}

#popular-this-week ul li {
    border-bottom: 1px solid var(--tab-blue);
    margin-bottom: 20px;
}

#popular-this-week .list-group{
    
}

#popular-this-week .list-group a {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray);
    background-color: #f2f7f8;
    border-bottom: 1px solid var(--tab-blue);
    padding: 20px 5px;
    align-items: center !important;
}

#popular-this-week a span {
    display: table;
    padding: 1px 10px;
    font-size: 0.8rem;
    background-color: var(--orange);
    color: var(--black);
    border-radius: 16px;
    width: auto;
}

.accordion-button:focus, .atom-section-header a:focus, .btn:focus, .atom-btn-white:focus {
    box-shadow: none !important;
}

:not(#top-bar #search-box-input.form-control):focus {
    border: 1px solid #d0d5dd !important;
    box-shadow: none !important;
    
}

#top-bar #search-box-input.form-control:focus {
    border-right: 0px;
    border-left: 0px;
    border-top: 1px solid #d0d5dd !important;
    border-bottom: 1px solid #d0d5dd !important;
    box-shadow: none !important;
}

.dropdown-menu {
    background-color: var(--white) !important;
    border: 1px solid #eaecf0 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 12px 16px -4px rgba(16,24,40,.08), 0px 4px 6px -2px rgba(16,24,40,.03) !important;
    border-radius: 8px !important;
    min-width: 260px !important;
    padding: 10px 16px !important;
    margin-top: 5px !important;
    z-index: 10 !important;
    transition: .3s !important;
}

.dropdown-header {
    color: var(--light-blue) !important;
    font-weight: 500 !important;
    font-size: 1.1rem !important;
}

.dropdown-item:hover {
    background-color: var(--light-gray) !important;
}

.atom-btn-white.active-primary.active,
.atom-btn-secondary:not(#top-bar .atom-btn-secondary), 
.atom-btn-secondary:not(#top-bar .atom-btn-secondary):focus {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}

